import * as React from 'react'
import Container from './container'
import DemoForm from './demo_form'
import Link from './link'
import { StaticImage } from 'gatsby-plugin-image'

const Covid19Footer = () => (<>
  <div className='Section Section--dark'>
    <Container>
      <DemoForm invert={true} />
    </Container>
  </div>
  <div className='Section Section--stripe pb-0'>
    <Container>
      <div className='text-center'>
        <h2 className='text-xl sm:text-2xl font-light text-center mt-0'>
          Need Help?
        </h2>
        <p className='text-md leading-relaxed'>
          Email us a
          {' '}
          <Link
            href='mailto:dealersupport@roadster.com'
            variant='underline'
            children='dealersupport@roadster.com'
          />
          {' '}
          or
          <br />
          contact your <b>Dealer Success Manager</b>
        </p>
        <div className='flex justify-center mt-4'>
          <StaticImage 
            src='../images/support-crew.jpg'
            placeholder='blurred'
            alt=''
            width={920}
          />
        </div>
      </div>
    </Container>
  </div>
</>)

export default Covid19Footer
