import * as React from 'react'
import { graphql, Link as GatsbyLink } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Subnav from '../../components/subnav'
import Container from '../../components/container'
import SimpleHeader from '../../components/simple_header'
import { GatsbyImage } from 'gatsby-plugin-image'
import Covid19Footer from '../../components/covid19_footer'
import Link from '../../components/link'

const Covid19ResourceCategoryPage = ({ data }) => {

  const content = data.contentfulCovid19ResourceCategory
  const resourcePages = data.allContentfulCovid19ResourceCategory.nodes
  const title = `COVID-19 ${content.title}`
  const isWebinars = content.url === 'webinars'
  const isStateList = content.url === 'state_guidelines'
  const isCaseStudies = content.url === 'case_studies'

  return (
    <Layout activeNav={true}>
      <Seo
        title={content.seoTitle || title}
        description={content.metaDescription || content.subtitle}
      />
      <SimpleHeader
        title={title}
        subtitle={content.subtitle}
      />
      <Subnav
        links={[
          ...resourcePages.map(item => (
            {
              key: `subnav_${item.url}`,
              to: `/covid19/${item.url}`,
              text: item.title
            }
          ))
        ]}
      />
      <div className='Section Section--stripe Section--condensed'>
        <Container size='lg'>
          <div className={`grid ${isStateList ? 'gap-3 grid-cols-2 sm:grid-cols-3 md:grid-cols-4' : 'gap-4 sm:grid-cols-2 md:grid-cols-3'}`}>
            {content.items.map((item, index) => {
              const hasPage = Boolean(item.items)
              const CardComponent = hasPage ? GatsbyLink : 'a'
              
              return (<React.Fragment key={`card_${item.url}`}>
                {isWebinars && index === 0 &&
                  <h2
                    className='col-span-full text-center text-xl sm:text-2xl mt-0 font-regular'
                    children='Previous Webinars Available On Demand'
                  />
                }
                {hasPage}
                <CardComponent
                  {...hasPage ? {
                    to: `/covid19/${content.url}/${item.url}`
                  } : {
                    href: item.url,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  }}
                  className='flex flex-col bg-white text-gray hover:text-body shadow-sm hover:shadow-lg transition-shadow group'
                >
                  {isCaseStudies ? <>
                    {item.image &&
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                      />
                    }
                    <hr className='my-0' />
                    <div className='p-3'>
                      {item.blurb &&
                        <p className='text-md m-0'>
                          {item.blurb}
                        </p>
                      }
                    </div>
                    <Link
                      variant='muted'
                      arrowDirection='right'
                      children='Read Story'
                      className='font-semi-bold uppercase text-body mt-auto px-3 pb-3 group-hover:opacity-100'
                    />
                  </> : <>
                    <div className='p-3'>
                      <h3 className='text-base m-0 text-body'>
                        {item.title}
                      </h3>
                      {item.blurb &&
                        <p className='text-md m-0 mt-1/4em'>
                          {item.blurb}
                        </p>
                      }
                    </div>
                    {item.image &&
                      <GatsbyImage
                        image={item.image.gatsbyImageData}
                        className='mt-auto'
                      />
                    }
                  </>}
                </CardComponent>
              </React.Fragment>)
            })}
          </div>
        </Container>
      </div>
      <Covid19Footer />
    </Layout>
  )
}

export default Covid19ResourceCategoryPage

export const pageQuery = graphql`
  query ($url: String) {
    contentfulCovid19ResourceCategory(url: {eq: $url}) {
      subtitle
      title
      url
      items {
        ... on ContentfulCovid19ResourcePage {
          title
          url
          blurb
          items {
            ... on ContentfulCovid19CaseStudy {
              id
            }
            ... on ContentfulCovid19ResourceItem {
              id
            }
          }
          image {
            gatsbyImageData(placeholder: BLURRED, width: 368)
          }
        }
        ... on ContentfulResourceItem {
          title
          url
          blurb
          image {
            gatsbyImageData(placeholder: BLURRED, width: 368)
          }
        }
      }
    }
    allContentfulCovid19ResourceCategory(sort: {fields: path_Id}) {
      nodes {
        title
        url
      }
    }
  }
`
